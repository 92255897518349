import React, {useEffect} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import { scrollTop } from "../utils/comman";
function EnquiryNow(props) {
  useEffect(() => {
    scrollTop();
  }, [])
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <div className="page-header py-3 py-lg-5 bg-light mb-5">
        <div className="container-xl">
        <div className="row">
              <div className="col-lg-5 pt-5">
              <h2 className="page-header-text text-4xl font-bold text-dark pt-lg-5 mt-lg-5">
               Ready to Work with us? Tell us more.
             </h2>

              </div>
              </div>

        </div>
      </div>
      <section className="enquiryNow-wrap pb-lg-5">
        <div className="container-xl pb-lg-5">
            <div className="row pb-lg-5">
              <div className="col-lg-6 order-2 order-lg-1">
              <div className="contact-info">
            <h4 className="text-dark">Other Ways to Get in Touch</h4>
            <h6>Our experts will answer any question in a timely and reliable manner. </h6>

            <div className="contacts-panel">
            <div className="media contacts-media py-4">
                <div className=" media-left mr-4">
                  <div className="circle-icon d-flex align-items-center justify-content-center bg-danger text-white">
                    <i className="las la-envelope la-2x"></i>
                  </div>
                </div>
                <div className="media-body">
                  <h6 className="text-dark font-sans-serif">Mail Us</h6>
                  <a href={"mailto:"+ process.env.REACT_APP_EMAIL} className="text-primary text-xl">{process.env.REACT_APP_EMAIL}</a>
                </div>
              </div>
              <div className="media contacts-media py-4">
                <div className=" media-left mr-4">
                  <div className="circle-icon d-flex align-items-center justify-content-center bg-danger text-white">
                    <i className="las la-phone la-2x"></i>
                  </div>
                </div>
                <div className="media-body">
                  <h6 className="text-dark font-sans-serif">Call Us</h6>
                  <a href={"tel: " + process.env.REACT_APP_CONTACT_NO} className="text-primary text-xl">{process.env.REACT_APP_CONTACT_NO}</a>  <br/>
                  <a href={"tel: " + process.env.REACT_APP_CONTACT_NO_2} className="text-primary text-xl">{process.env.REACT_APP_CONTACT_NO_2}</a>
                </div>
              </div>
              <div className="media contacts-media py-4">
                <div className=" media-left mr-4">
                  <div className="circle-icon d-flex align-items-center justify-content-center bg-danger text-white">
                    <i className="las la-envelope la-2x"></i>
                  </div>
                </div>
                <div className="media-body">
                  <h6 className="text-dark font-sans-serif">Locate Us</h6>
                  <p className="text-xl">
                    {process.env.REACT_APP_CONTACT_ADDRESS}
                  </p>
                </div>
              </div>
              <hr className="bg-white my-4"/>

              <div className="social-networks d-flex align-items-center">
                <h6 className="mr-4">Connect With Us</h6>
                <a className="facebook" href="#"><i className="lab la-facebook-f"></i></a>
                <a className="twitter" href="#"><i className="lab la-twitter"></i></a>
                <a className="linkedin" href="#"><i className="lab la-linkedin-in"></i></a>
              </div>
            </div>
          </div>
              </div>
              <div className="col-lg-6 mb-4 mb-lg-0 order-1 order-lg-2">
              <div className="enquiryForm-card card border-0 bg-primary">
              <div className="card-body p-lg-5">
              <h4 className="font-bold text-white">
              Simply fill the following form and we will respond with detail about costs and setup process :
              </h4>
              <h6 className="text-white font-italic">
          We'll respond within next 1 working day.
            </h6>
                <div className="contact-form pt-2 pt-md-3">

              <div className="form-group">
                <label for="">Your Name*</label>
                <input type="text" className="form-control" placeholder="Name"/>
              </div>
              <div className="form-group">
                <label for="">E-mail *</label>
                <input type="text" className="form-control" placeholder="Email"/>
              </div>
              <div className="form-group">
                <label for="">Your Phone</label>
                <input type="text" className="form-control" placeholder="Phone"/>
              </div>
              <div className="form-group">
                <label for="">Subject</label>
                <input type="text" className="form-control" placeholder="Phone"/>
              </div>


              <div className="form-group">
                <label for="">Message</label>
                <textarea name="" className="form-control" placeholder="Message/Other"></textarea>
              </div>
              <div className="form-group mb-0"><button className="btn btn-danger btn-block">Submit</button></div>
              <div className="form-group pt-3">
              <p className="text-white text-sm">

Note: You can only select one product at a time. Kindly select the product that is nearest to your requirement.
If you want to buy more than one product, kindly refill the enquiry form.
              </p>
            </div>
            </div>
              </div>
              </div>
              </div>
            </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default EnquiryNow;
