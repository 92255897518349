import React from 'react'
import logoimg from '../images/logo/logo.png';
function Logo() {
    return(
        <>
            <a className="navbar-brand" href="/"> 
                <img src={logoimg} className="img-fluid" alt="Market Place" /> 
            </a>
        </>
    )
}
export default Logo