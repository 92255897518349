import React from 'react'
function Achievement() {
    const mystyle = {
        visibility: "visible",
        animationName: "fadeInUp",
      };
    return(
        <>
           <section className="company-profile pt-5">
                <div className="container-xl">
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="title wow fadeInUp">
                            <h6 className="text-danger"> Achievement </h6>
                            <h2 className="title text-dark font-bold mb-4">
                            We have more then 15 years of experience
                            </h2>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row justify-content-between">
                                <div className="col-md-4 mb-3">
                                    <div className="card border-0 border-radius-lg bg-primary text-center text-white h-100 wow fadeInUp" style={mystyle}>
                                        <div className="card-body p-5">
                                            <h4 className="text-4xl">50+ </h4>
                                            <h6 className="mb-0">Mobile Apps
                                                Developed
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="card border-0 border-radius-lg bg-success text-center text-white h-100 wow fadeInUp" style={mystyle}>
                                        <div className="card-body p-5">
                                        <h4 className="text-4xl">90+</h4>
                                        <h6 className="mb-0">Fully satisfied and Happy clients</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="card border-0 border-radius-lg bg-danger text-center text-white h-100 wow fadeInUp" style={mystyle}>
                                        <div className="card-body p-5">
                                        <h4 className="text-4xl">35+</h4>
                                        <h6 className="mb-0">Dedicated Developers & Designers</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

           </section>
        </>
    )
}
export default Achievement