import React from 'react'
import { PrimaryMenu } from './PrimaryMenu'
import HomePage from '../Pages/HomePage';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
function PrimaryNavebar() {
    return(
        <>
            <button className="navbar-toggler d-lg-none border-0" type="button" data-toggle="collapse"
            data-target="#mainNavigation" aria-controls="mainNavigation" aria-expanded="false"
            aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
            <div id="mainNavigation" className="collapse navbar-collapse">
                <ul className="navbar-nav justify-content-end ml-auto font-medium">

                {PrimaryMenu.map((item, index) => {
                    if(!('comp' in item)){
                        return (
                            <li className="nav-item">
                            <a href={item.externalurl} className={item.cName}>{item.title}</a>
                            </li>
                        );
                    }
                    else{

                        if(!('submenu' in item)){
                            return (
                                <li className="nav-item" key={index}>

                                <NavLink
                                style={({ isActive }) => {
                                    return {
                                        color: isActive ? "#e95858" : ""
                                    };
                                }}
                                key={index} to={item.path} className={item.cName}>
                                    {item.title}
                                </NavLink>

                                </li>
                            );
                        }
                        else{
                            return (
                                <>
                             <li className="nav-item dropdown" key={index}>
                                 <NavLink key={index} to={item.path} className={item.cName} data-toggle="dropdown">
                                     {item.title}
                                 </NavLink>
                                 <ul className="dropdown-menu">
                                    {item.submenu.map((subitem, subindex) => {
                                        return (
                                            <li className="dropdown-item" key={subitem.title}>
                                                <NavLink key={subitem.path} className={subitem.cName} to={subitem.path}>{subitem.title}</NavLink>
                                            </li>
                                        )
                                    })}
                                 </ul>
                             </li>

                             </>
                            );

                         }
                    }


                })}
                </ul>
                {/* <a className="btn btn-success" href="#">Enquiry Now</a> */}
                <NavLink key="enquiry-now" className="btn btn-success" to="enquiry-now">Enquiry Now</NavLink>
            </div>
        </>
    )
}
export default PrimaryNavebar