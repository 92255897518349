import React from 'react'
import banner1 from '../../images/assets/banner-image.png';
import banner2 from '../../images/assets/banner-image.png';
import { Carousel } from 'react-bootstrap';
function Banner() {
    const prevIcon = <i className="la la-angle-left la-2x text-dark"></i>;
    const nextIcon = <i className="la la-angle-right la-2x text-dark"></i>;
    return(
        <>
           <div className="banner-wrapper position-relative">

            <Carousel controls="true" nextLabel="" nextIcon={nextIcon} prevLabel="" prevIcon={prevIcon} indicators={false}>
                <Carousel.Item interval={3000}>
                <div className="banner-caption py-lg-5 my-lg-5">
                <div className="container-xl">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5">
                        <div className="banner-text py-5 d-md-block">

                            <h2 className="text-4xl font-bold text-dark mb-md-4">
                            Anyone, anywhere, can start a delivery business
                            </h2>
                            <h6 className="text-body font-bold">Purchase a Ready Made On Demand Application from us and start
                            making money right from day 1! Team MarketPlace supports you in fulfilling your dream to be a
                            successful Entrepreneur in just 5-7 business days!</h6>

                            <div className="banner_btns py-lg-3">
                            <a href="" className="btn btn-danger mr-2">Request An Estimation</a>
                            <a href="" className="btn btn-outline-primary">Schedule a Call</a>

                            </div>

                        </div>
                        </div>
                        <div className="col-lg-7">
                        <div className="banner-image">
                            <img src={banner1} alt="banner" className="img-fluid" />
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                <div className="banner-caption py-lg-5 my-lg-5">
                <div className="container-xl">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5">
                        <div className="banner-text py-5 d-md-block">

                            <h2 className="text-4xl font-bold text-dark mb-md-4">
                            Anyone, anywhere, can start a delivery business
                            </h2>
                            <h6 className="text-body font-bold">Purchase a Ready Made On Demand Application from us and start
                            making money right from day 1! Team MarketPlace supports you in fulfilling your dream to be a
                            successful Entrepreneur in just 5-7 business days!</h6>

                            <div className="banner_btns py-lg-3">
                            <a href="" className="btn btn-danger mr-2">Request An Estimation</a>
                            <a href="" className="btn btn-outline-primary">Schedule a Call</a>

                            </div>

                        </div>
                        </div>
                        <div className="col-lg-7">
                        <div className="banner-image">
                            <img src={banner1} alt="banner" className="img-fluid" />
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                </Carousel.Item>
            </Carousel>
            </div>
        </>
    )
}
export default Banner