import React from "react";
import { CompanyMenu } from "./CompanyMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
function CompanyNavbar() {
  return (
    <>
      <div class="col-md-6">
        <div class="footer-link-widgets">
          <h6 class="text-white text-uppercase">Company</h6>
          <ul class="list-unstyled">
            {CompanyMenu.map((item, index) => {
              if (!("comp" in item)) {
                return (
                  <li key={index}>
                    <a href={item.externalurl} className={item.cName}>
                      {item.title}
                    </a>
                  </li>
                );
              } else {
                return (
                  <li key={index}>
                    <NavLink key={index} to={item.path} className={item.cName}>
                      {item.title}
                    </NavLink>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
export default CompanyNavbar;
