import React, {useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Banner from '../Components/Home/Banner';
import Clients from '../Components/Home/Clients';
import Promotion from '../Components/Home/Promotion';
import BusinessSolution from '../Components/Home/BusinessSoluttion';
import MobileApplication from '../Components/Home/MobileApplication';
import Achievement from '../Components/Home/Achievement';
import AppSummary from '../Components/Home/AppSummary';
import Features from '../Components/Home/Features';
import Proccess from '../Components/Home/Proccess';
import {Helmet} from "react-helmet";
import { scrollTop } from "../utils/comman";
function HomePage(props) {
    useEffect(() => {
        scrollTop();
      }, [])
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />   
            <Banner />
            <Clients />
            <Promotion />
            <BusinessSolution />
            <MobileApplication />
            <Achievement />
            <AppSummary />
            <Features />
            <Proccess />
            <Footer />
        </>
    )
}
export default HomePage