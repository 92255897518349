import React from 'react'

function Promotion() {
    return(
        <>
              <section className="promotion-wrap my-5">
                <div className="container-xl my-5">
                <div className="row">
                    <div className="col-md-5">
                    <div className="promotion-left position-relative bg-danger h-100 py-5 pr-5 pl-0">
                        <h2 className="text-white">Market Place</h2>
                        <h6 className="text-white font-semibold">Become a part of the No.1
                        <br className="d-none d-md-block" />
                        delivery system in the market
                        </h6>
                    </div>
                    </div>
                    <div className="col-md-7">
                    <div className="promotion-right position-relative bg-primary h-100 p-5">
                        <h5 className="text-white font-semibold pb-4">
                        Purchase a Ready Made On Demand Application from us and start making money right from day 1! Team
                        MarketPlace supports you in fulfilling your dream to be a successful Entrepreneur in just 5-7 business
                        days!
                        </h5>
                        <a href="#" className="btn-fancy btn-fancy-danger text-white"> Read More <i
                            className="la la-long-arrow-right la-lg"></i></a>
                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}
export default Promotion