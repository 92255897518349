import React, {useEffect} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

import image1 from "../images/assets/business-solution.png";
import Testimonial from "../Components/Testimonial";
import banner1 from "../images/assets/banner-image.png";
import graphicalFlow from "../images/assets/graphical-flow.png";
import webDeveloperSVG from "../images/assets/web-developer-with-laptop.svg";
import androidSVG from "../images/assets/android.svg";
import appleSVG from "../images/assets/apple.svg";
import javaSVG from "../images/assets/java.svg";


import { Carousel } from "react-bootstrap";
import appimg1 from "../images/assets/1.jpg";
import appimg2 from "../images/assets/2.jpg";
import OwlCarousel from "react-owl-carousel";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import OtherApps from "../Components/OtherApps";
import Proccess from "../Components/Home/Proccess";
import {Helmet} from "react-helmet";
import { scrollTop } from "../utils/comman";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function DeliveryApp(props) {
  const prevIcon = <i className="la la-angle-left la-2x text-dark"></i>;
  const nextIcon = <i className="la la-angle-right la-2x text-dark"></i>;
  useEffect(() => {
    scrollTop();
  }, [])
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <div className="banner-wrapper position-relative">
        <Carousel
          controls="false"
          nextLabel=""
          nextIcon={nextIcon}
          prevLabel=""
          prevIcon={prevIcon}
          indicators={false}
        >
          <Carousel.Item interval={3000}>
            <div className="banner-caption py-lg-5 my-lg-5">
              <div className="container-xl">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-5">
                    <div className="banner-text py-5 d-md-block">
                      <h2 className="text-4xl font-bold text-dark mb-md-4">
                        On Demand Food Delivery Solution
                      </h2>
                      <h6 className="text-body font-bold">
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Sunt quis obcaecati laboriosam ipsam et?
                        Recusandae vitae accusamus architecto doloremque
                        nesciunt. Corrupti doloremque quod sequi vero expedita
                        natus qui eligendi quaerat aut? Eveniet aliquid
                        praesentium sed aliquam. Sit doloribus fuga illum
                        nesciunt assumenda rerum. Fuga mollitia, voluptatum
                        laborum facilis laboriosam architecto deserunt facere
                        necessitatibus sed deleniti!
                      </h6>

                      <div className="banner_btns py-lg-3">
                        <a href="" className="btn btn-danger mr-2">
                          Demo
                        </a>
                        <a href="" className="btn btn-primary mr-2">
                          Pricing
                        </a>
                        <a href="" className="btn btn-success mr-2">
                          Buy Now
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="banner-image">
                      <img src={window.location.origin + banner1} alt="banner" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <section className="business-wrap">
        <div className="container-xl pb-3 pb-md-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="image-module mb-4 pb-5 pb-md-0 wow fadeInUp">
                <img
                  src={window.location.origin + image1}
                  alt="business solution"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-info">
                <div className="title wow fadeInUp">
                  <h6 className="text-danger">Do Not Let the</h6>
                  <h2 className="title text-dark font-bold mb-4">
                    Corona Virus CoVid 19 Pandemic Affect your business!
                  </h2>
                </div>
                <div className="pb-3">
                  <p>
                    The global fabric of entrepreneurship has changed during the
                    last two years since the Corona Virus CoVid 19 pandemic hit
                    the world so hard. Smart entrepreneurs have realized that it
                    is time to up their game and to switch on to more profitable
                    avenues rather than sticking to conventional business ideas.
                    To meet the market demands, we have created an app that
                    specifically ensures that nothing in the name of business
                    gets affected or halted during these precarious times.
                  </p>

                  <p>
                    {" "}
                    Many have suffered major financial losses during the
                    pandemic, but your Grubhub clone can now ensure that more
                    and more people can find a way to earn their living in spite
                    of the pandemic as your customers continue to thrive on by
                    getting access to their favorite meals.
                  </p>

                  <p>
                    {" "}
                    We have added new features such as the Safety badge,
                    contactless deliveries and much more. Continue on to find
                    out more about the Corona Virus CoVid 19 safety features in
                    our brand new UberEats Clone app.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />

      <section className="app-screens pt-5">
        <div className="container-xl pb-3 pb-md-5">
          <div className="title wow fadeInUp text-center">
            <h6 className="text-danger">Choose Clone Food Delivery App </h6>
            <h2 className="title text-dark font-bold mb-4">
              Making Food Delivery Experiences Smooth
            </h2>
          </div>
          <Tab.Container defaultActiveKey="customerApp">
            <Nav
              variant="tabs"
              className="custom-tabs px-0 pt-md-5 justify-content-between"
            >
              <Nav.Item>
                <Nav.Link eventKey="customerApp">Customer App</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="driverApp">Driver App</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="restaurantApp">Restaurant App</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="website">Website</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="adminPanel">Admin Panel</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="py-5" id="appsTabContent">
              <Tab.Pane eventKey="customerApp" transition={true}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg order-lg-1 order-md-2">
                    <h4 className="my-5 text-primary">
                      Here are some of the different features that are available
                      in the app of the customer,
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4 order-lg-2 order-md-1">
                    <OwlCarousel
                      className="customer-apps-carousel owl-carousel owl-theme"
                      items={1}
                      loop
                      dots={false}
                      margin={15}
                    >
                      <div className="item app-item">
                        <img src={window.location.origin + appimg1} alt="user" />
                      </div>
                      <div className="item app-item">
                        <img src={window.location.origin + appimg2} alt="user" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="driverApp" transition={true}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg order-lg-1 order-md-2">
                    <h4 className="my-5 text-primary">
                      Here are some of the different features that are available
                      in the app of the customer,
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4 order-lg-2 order-md-1">
                    <OwlCarousel
                      className="customer-apps-carousel owl-carousel owl-theme"
                      items={1}
                      loop
                      dots={false}
                      margin={15}
                    >
                      <div className="item app-item">
                        <img src={window.location.origin + appimg1} alt="user" />
                      </div>
                      <div className="item app-item">
                        <img src={window.location.origin + appimg2} alt="user" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="restaurantApp" transition={true}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg order-lg-1 order-md-2">
                    <h4 className="my-5 text-primary">
                      Here are some of the different features that are available
                      in the app of the customer,
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4 order-lg-2 order-md-1">
                    <OwlCarousel
                      className="customer-apps-carousel owl-carousel owl-theme"
                      items={1}
                      loop
                      dots={false}
                      margin={15}
                    >
                      <div className="item app-item">
                        <img src={window.location.origin +appimg1} alt="user" />
                      </div>
                      <div className="item app-item">
                        <img src={window.location.origin +appimg2} alt="user" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="website" transition={true}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg order-lg-1 order-md-2">
                    <h4 className="my-5 text-primary">
                      Here are some of the different features that are available
                      in the app of the customer,
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4 order-lg-2 order-md-1">
                    <OwlCarousel
                      className="customer-apps-carousel owl-carousel owl-theme"
                      items={1}
                      loop
                      dots={false}
                      margin={15}
                    >
                      <div className="item app-item">
                        <img src={window.location.origin +appimg1} alt="user" />
                      </div>
                      <div className="item app-item">
                        <img src={window.location.origin +appimg2} alt="user" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="adminPanel" transition={true}>
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg order-lg-1 order-md-2">
                    <h4 className="my-5 text-primary">
                      Here are some of the different features that are available
                      in the app of the customer,
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-share-alt la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Social Media Login and Signup</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="media align-items-center mb-4">
                          <div className="media-left mr-3">
                            <span className="circle-icon d-flex align-items-center justify-content-center bg-primary-light">
                              <i className="las la-search la-2x text-primary"></i>
                            </span>
                          </div>
                          <div className="media-body">
                            <h6>Advanced Search Filter</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4 order-lg-2 order-md-1">
                    <OwlCarousel
                      className="customer-apps-carousel owl-carousel owl-theme"
                      items={1}
                      loop
                      dots={false}
                      margin={15}
                    >
                      <div className="item app-item">
                        <img src={window.location.origin +appimg1} alt="user" />
                      </div>
                      <div className="item app-item">
                        <img src={window.location.origin +appimg2} alt="user" />
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          <hr />
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <h5>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Reprehenderit, magnam.
              </h5>
            </div>
            <div className="col-md-auto">
              <a href="#" className="btn btn-success mr-2">
                Buy Now
              </a>
              <a href="#" className="btn btn-outline-primary">
                Demo
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="features-wrap py-3 py-md-5 bg-light">
        <div className="container-xl pb-3 pb-md-5">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="title py-3 py-lg-5">
                <h6 className="text-danger">
                  A Collection of Prominent Features
                </h6>
                <h2 className="title text-dark font-bold mb-4">
                  Delivering Exceptional
                  <br className="d-none d-lg-block" />
                  User restaurantApps
                </h2>
              </div>
            </div>
            <div className="col-md-6">
              <h6>
                Technology Platform for On-Demand Businesses. Including Apps for
                Users, Store Owners, Administrators, Drivers for Real-time
                Tracking and more, to increase your income.
              </h6>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                <div className="card-body">
                  <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                    <i className="las la-thumbs-up la-2x text-white"></i>
                  </span>
                  <h4 className="feature-title font-bold text-primary">
                    Fast Performance
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ipsum lacus, feugiat venenatis ac. Nunc facilisi duis
                    molestie elit orci, vestibulum.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                <div className="card-body">
                  <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                    <i className="las la-sliders-h la-2x text-white"></i>
                  </span>
                  <h4 className="feature-title font-bold text-primary">
                    Easy Customize
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ipsum lacus, feugiat venenatis ac. Nunc facilisi duis
                    molestie elit orci, vestibulum.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                <div className="card-body">
                  <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                    <i className="las la-gem la-2x text-white"></i>
                  </span>
                  <h4 className="feature-title font-bold text-primary">
                    {" "}
                    Pure & Elegant
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ipsum lacus, feugiat venenatis ac. Nunc facilisi duis
                    molestie elit orci, vestibulum.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                <div className="card-body">
                  <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                    <i className="las la-shield-alt la-2x text-white"></i>
                  </span>
                  <h4 className="feature-title font-bold text-primary">
                    Secure Data
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ipsum lacus, feugiat venenatis ac. Nunc facilisi duis
                    molestie elit orci, vestibulum.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                <div className="card-body">
                  <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                    <i className="las la-clock la-2x text-white"></i>
                  </span>
                  <h4 className="feature-title font-bold text-primary">
                    Secure Data
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ipsum lacus, feugiat venenatis ac. Nunc facilisi duis
                    molestie elit orci, vestibulum.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                <div className="card-body">
                  <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                    <i className="las la-headphones la-2x text-white"></i>
                  </span>
                  <h4 className="feature-title font-bold text-primary">
                    Secure Data
                  </h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Enim ipsum lacus, feugiat venenatis ac. Nunc facilisi duis
                    molestie elit orci, vestibulum.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-between align-items-center py-5">
            <div className="col-md-6">
              <h5>
                Tech, Coding stuff & Hassle on us, Growth on you.
                <br />
                How cool is that ?
              </h5>
            </div>
            <div className="col-md-auto">
              <a href="#" className="btn btn-success mr-2">
                Request An Estimation
              </a>
              <a href="#" className="btn btn-outline-primary">
                Schedule a Call
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="service-packages-wrap py-3 py-md-5">
        <div className="container-xl">
          <div className="title text-center py-3 py-lg-5">
            <h6 className="text-danger">Here's What You Get</h6>
            <h2 className="title text-dark font-bold mb-4">Key Takeaways</h2>
            <h6>
              The premise of an on demand food delivery application such as the
              UberEats clone sits on a very complex structure of codes and
              schematics. In order to ensure that your business goes on
              smoothly, you will need more than just a single app. There is a
              bundle that cohesively comes together as a system to ensure on
              demand delivery takes place effectively. Take a look at everything
              you get when you purchase the UberEats Clone from us.{" "}
            </h6>
          </div>

          <div className="row row-cols-sm-6 row-cols-md-3 row-cols-xl-3 text-center text-lg-left">
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-user la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        User iOS APP
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-taxi la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Delivery Driver iOS APP
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-store la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Restaurant iOS APP
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-taxi la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Delivery Driver Android APP
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-store-alt la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Food Ordering Kiosk App
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-store la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Restaurant Android APP
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-desktop la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Main Website
                        <span className="d-block text-sm text-dark font-medium">
                          (Includes ordering Food)
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-user la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        <small>User Web Panel</small>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-truck-loading la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Delivery Driver Web Panel
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-store-alt la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Restaurant Web Panel
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-file-invoice la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Billing Panel
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-user-astronaut la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Dispatcher Panel
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <div className="card border-radius-lg package-card bg-white shadow h-100 wow fadeInUp">
                <div className="card-body">
                  <div className="media flex-column flex-lg-row align-items-center">
                    <div className="media-left mb-3 mb-lg-0 mr-lg-3">
                      {" "}
                      <span className="circle-icon d-flex align-items-center justify-content-center mx-auto bg-success">
                        <i className="las la-user-tie la-2x text-white"></i>
                      </span>
                    </div>
                    <div className="media-body">
                      <h4 className="service-package-title font-bold text-primary">
                        Administrator Web Panel
                        <span className="d-block text-sm text-dark font-medium">
                          {" "}
                          to manage all - Payments, Commissions, Customer,
                          Restaurants, Delivery Driver, etc.
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row justify-content-between align-items-center pt-5">
            <div className="col-md-6">
              <h5>
                Tech, Coding stuff & Hassle on us, Growth on you.
                <br />
                How cool is that ?
              </h5>
            </div>
            <div className="col-md-auto">
              <a href="#" className="btn btn-success mr-2">
                Request An Estimation
              </a>
              <a href="#" className="btn btn-outline-primary">
                Schedule a Call
              </a>
            </div>
          </div>
        </div>
      </section>
      <OtherApps currentApp="ABC" />
      <section className="graphical-flow-wrap py-3 py-md-5">
        <div className="container-xl">
          <div className="title text-center py-3 py-lg-5">
            <h6 className="text-danger">Flow of Food</h6>
            <h2 className="title text-dark font-bold mb-4">
              Restaurant Delivery Apps
            </h2>
          </div>
          <div className="graphical-flow">
            <img
              src={window.location.origin +graphicalFlow}
              alt="Graphical Flow"
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <section className="technology-wrap py-3 py-md-5 bg-light">
        <div className="container-xl">

            <div className="title text-center py-3 py-lg-5">
                <h6 className="text-danger">We use only state of the art technology to ensure that you can get the best
                    results.</h6>
                <h2 className="title text-dark font-bold mb-4">
                    Technologies Used
                </h2>

            </div>


            <div className="row justify-content-between">
                <div className="col-lg-6">
                    <img src={window.location.origin +webDeveloperSVG} alt="Mobile Application" />
                </div>
                <div className="col-lg-5">
                    <div className="row row-cols-md-3 row-cols-lg-3">
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                              <img src={window.location.origin +androidSVG} alt="androidSVG" />
                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                              <img src={window.location.origin +appleSVG} alt="androidSVG" />
                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                              <img src={window.location.origin +javaSVG} alt="androidSVG" />
                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                              <img src={window.location.origin +androidSVG} alt="appleSVG" />
                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">

                                
                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                                

                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                                

                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                               



                            </div>
                        </div>
                        <div className="cols mb-5">
                            <div className="technology-block d-flex justify-content-center align-items-center">
                        
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>
    </section>
      <Proccess />
      <Footer />
    </>
  );
}

export default DeliveryApp;
