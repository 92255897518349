import React from 'react';
import image1 from '../../images/assets/watch-app.png';
import image2 from '../../images/assets/watch-app.png';
function AppSummary() {
    return (
        <>
              <section className="app-summary-odd">
                    <div className="container-xl pb-3 pb-md-5">
                    <div className="row pt-5">
                        <div className="col-lg-6 order-1 order-md-2">
                        <div className="app-image mb-4 pb-5 pb-md-0 wow fadeInUp">
                            <img src={image1} alt="app" className="img-fluid" />
                        </div>
                        </div>
                        <div className="col-lg-6 order-2 order-md-1">
                        <div className="app-info bg-danger py-5">
                            <div className="title wow fadeInUp">
                            <h2 className="title text-white font-bold mb-4">
                                Taxi Booking Apple<br className="d-none d-md-block" /> Watch App
                            </h2>
                            <h6 className="text-semibold text-white">Hail A Taxi With A Tap On The Wrist Using
                            <br className="d-none d-md-block"/>Our Apple Watch App!</h6>
                            </div>
                            <ul className="list-style-check text-white">
                            <li>Customisable client apps</li>
                            <li>Store admin web</li>
                            <li>Delivery apps</li>
                            <li>Store management app</li>
                            </ul>
                            <a href="#" className="btn-fancy btn-fancy-primay text-white"> Learn More <i
                                className="la la-long-arrow-right la-lg"></i></a>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
                <section className="app-summary-even">
                    <div className="container-xl pb-3 pb-md-5">
                    <div className="row pt-5">
                        <div className="col-lg-6">
                        <div className="app-image mb-4 pb-5 pb-md-0 wow fadeInUp">
                            <img src={image2} alt="app" className="img-fluid" />
                        </div>
                        </div>
                        <div className="col-lg-6">
                        <div className="app-info bg-primary py-5">
                            <div className="title wow fadeInUp">
                            <h2 className="title text-white font-bold mb-4">
                                Taxi Booking Apple<br className="d-none d-md-block" /> Watch App
                            </h2>
                            <h6 className="text-semibold text-white">Hail A Taxi With A Tap On The Wrist Using
                            <br className="d-none d-md-block" />Our Apple Watch App!</h6>
                            </div>
                            <ul className="list-style-check text-white">
                            <li>Customisable client apps</li>
                            <li>Store admin web</li>
                            <li>Delivery apps</li>
                            <li>Store management app</li>
                            </ul>
                            <a href="#" className="btn-fancy btn-fancy-danger text-white"> Learn More <i
                                className="la la-long-arrow-right la-lg"></i></a>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>
        </>
    )
}
export default AppSummary