import React from "react";
import HelpCenterNavebar from "./HelpCenterNavbar";
import CompanyNavbar from "./CompanyNavbar";
import SocialNavbar from "./SocialNavbar";
import Proccess from "./Home/Proccess";
function Footer() {
  let year = new Date().getFullYear();
  return (
    <>
      <footer className="footer py-md-5 bg-dark">
        <div className="container-xl">
          <div className="footer-conatcts-wrap">
            <div className="footer-contacts-module bg-danger">
              <div className="row justify-content-between align-items-center">
                <div className="col-md">
                  <div className="title">
                    <h2 className="title text-white font-bold mb-0">
                      Need a quick help <br className="d-none d-block" />
                      or have questions?
                    </h2>
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="media align-items-center text-white">
                    <div className="media-left text-center">
                      <i className="la la-phone la-2x la-stack"></i>
                    </div>
                    <div className="media-body">
                      <h6 className="font-semibold">
                        <a href={"tel: " + process.env.REACT_APP_CONTACT_NO} className="text-white">
                          {process.env.REACT_APP_CONTACT_NO}
                        </a><br />
                        <a href={"tel: " + process.env.REACT_APP_CONTACT_NO_2} className="text-white">
                          {process.env.REACT_APP_CONTACT_NO_2}
                        </a>
                        
                      </h6>
                    </div>
                  </div>
                  <div className="media align-items-center text-white">
                    <div className="media-left text-center">
                      <i className="la la-envelope la-2x la-stack"></i>
                    </div>
                    <div className="media-body">
                      <h6 className="font-semibold">
                        <a
                          href={"mailto:"+ process.env.REACT_APP_EMAIL}
                          className="text-white"
                        >
                          {process.env.REACT_APP_EMAIL}
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-middle pt-5 text-md-left text-center">
            <div className="row row-divider-light mt-md-5">
              <div className="col-md-6">
                <div className="footer-link-widgets">
                  <h6 className="text-white text-uppercase">
                    Popular Products
                  </h6>
                  <ul className="list-unstyled row row-cols-1 row-cols-md-2">
                    <li className="col">
                      <a href="#"> All in one delivery </a>
                    </li>
                    <li className="col">
                      <a href="#"> Food delivery app </a>
                    </li>
                    <li className="col">
                      <a href="#"> water delivery app </a>
                    </li>
                    <li className="col">
                      <a href="#"> Grocery delivery app </a>
                    </li>
                    <li className="col">
                      <a href="#"> Medicine delivery app </a>
                    </li>
                    <li className="col">
                      <a href="#"> On-demand delivery</a>
                    </li>
                    <li className="col">
                      <a href="#"> Super admin panel features</a>
                    </li>
                    <li className="col">
                      <a href="#"> All in one delivery</a>
                    </li>
                    <li className="col">
                      <a href="#">Food delivery app </a>
                    </li>
                    <li className="col">
                      <a href="#">water delivery app</a>
                    </li>
                    <li className="col">
                      <a href="#">Grocery delivery app</a>
                    </li>
                    <li className="col">
                      <a href="#">Medicine delivery app</a>
                    </li>
                    <li className="col">
                      <a href="#">On-demand delivery </a>
                    </li>
                    <li className="col">
                      <a href="#">Super admin panel features</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 pl-md-5">
                <div className="row">
                  <CompanyNavbar />
                  <HelpCenterNavebar />
                  <SocialNavbar />
                </div>
              </div>
            </div>
          </div>
          <div className="copyright text-center wow fadeInUp">
            <p className="text-light">
              
              Copyright &copy; {year} {process.env.REACT_APP_SITE_TITLE}. All Rights reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
