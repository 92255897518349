import React, {useState, useEffect} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import client_img1 from '../images/assets/user.png';

function Testimonial(props) {
    const [testimonial_list, setTestimonialList] = useState([
        {
            content: 'They spend over 50% of our waking life at work. Devoting that amount of time to a career that brings you challenge, growth and fulfillment is critical life.',
            name: 'Clara Seinfield',
            designation: 'client',
            image: client_img1,
        },
        {
            content: 'They spend over 50% of our waking life at work. Devoting that amount of time to a career that brings you challenge, growth and fulfillment is critical life.',
            name: 'Clara Seinfield 1',
            designation: 'client 1',
            image: client_img1,
        },
        {
            content: 'They spend over 50% of our waking life at work. Devoting that amount of time to a career that brings you challenge, growth and fulfillment is critical life.',
            name: 'Clara Seinfield 2',
            designation: 'client 2',
            image: client_img1,
        },
        ]);
  return (
    <>
      <section className="testimonials-wrapper bg-light py-5">
        <div className="container-xl">
          <div className="title wow fadeInUp text-center">
            <h6 className="text-danger">Testimonial </h6>
            <h2 className="title text-dark font-bold mb-4">
              See what our clients have to say
              <br />
              after working with us
            </h2>
          </div>
          <OwlCarousel
            className="testimonials-carousel owl-carousel owl-theme pt-4 wow fadeInUp"
            items={2}
            loop
            dots={true}
            margin={15}
          >
              {testimonial_list.map(testimonial => (
                  <div className="item testimonials-item h-100 p-3">
                  <div className="card border-0 mb-3 testimonials-card">
                    <div className="card-body p-5">
                      <div className="testimonials-text pb-5 pb-md-4">
                        <p className="lead font-italic font-medium">
                          {testimonial.content}
                        </p>
                      </div>
                      <div className="testimonials-media media align-items-center mb-4">
                        <div className="testimonials-picture media-left mr-3">
                          <img src={testimonial.image} alt="user" />
                        </div>
                        <div className="media-body">
                          <h6 className="text-primary">{testimonial.name}</h6>
                          <p className="text-muted text-sm">{testimonial.designation}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </OwlCarousel>
        </div>
        
      </section>
      
    </>
  );
}

export default Testimonial;
