import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import client1 from '../../images/assets/client-01.png';
import client2 from '../../images/assets/client-02.png';
import client3 from '../../images/assets/client-03.png';
import client4 from '../../images/assets/client-04.png';
import client5 from '../../images/assets/client-05.png';
function Clients() {
    return(
        <section className="clients-wrap pb-5">
            <div className="container-xl">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-3">
                    <h5 className="text-primary">
                        Used by over 1,000,000 people worldwide
                    </h5>
            
                    </div>
                    <div className="col-md-9">
                        <OwlCarousel className="clients-carousel owl-carousel owl-theme wow fadeInUp" items={5} loop nav dots={false} margin={5}>
                            <div className="item p-3 h-100">
                                <div className="clients-box rounded p-2 bg-white h-100">
                                    <img src={client1} alt="client" className="img-fluid" />
                                </div>
                            </div>
                            <div className="item p-3 h-100">
                                <div className="clients-box rounded p-2 bg-white h-100">
                                    <img src={client2} alt="client" className="img-fluid" />
                                </div>
                            </div>
                            <div className="item p-3 h-100">
                                <div className="clients-box rounded p-2 bg-white h-100">
                                    <img src={client3} alt="client" className="img-fluid" />
                                </div>
                            </div>
                            <div className="item p-3 h-100">
                                <div className="clients-box rounded p-2 bg-white h-100">
                                    <img src={client4} alt="client" className="img-fluid" />
                                </div>
                            </div>
                            <div className="item p-3 h-100">
                                <div className="clients-box rounded p-2 bg-white h-100">
                                    <img src={client5} alt="client" className="img-fluid" />
                                </div>
                            </div>
                        </OwlCarousel>
                    
                    </div>
                </div>
            </div>
      </section>
    )
}
export default Clients