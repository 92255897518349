import React from 'react';
import HomePage from '../Pages/HomePage';
import AboutUs from '../Pages/AboutUs';
import AbcPage from '../Pages/AbcPage';
import DelieryPage from '../Pages/DelieryPage';
import TestPage from '../Pages/TestPage';
import DeliveryApp from '../Pages/DeliveryApp';
export const PrimaryMenu = [
    {
        title: 'Home',
        path: '/',
        cName: 'nav-link',
        comp: <HomePage title={process.env.REACT_APP_SITE_TITLE}/>,
    },
    {
        title: 'Deliery Apps',
        path: '/delieryapps',
        cName: 'nav-link dropdown-toggle',
        comp: <DelieryPage title={`${process.env.REACT_APP_SITE_TITLE}: Deliery Apps`}/>,
        submenu: [
            {
                title: 'Food Delivery',
                path: '/delieryapps/food-delivery',
                cName: 'nav-link',
                comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Food Delivery`}/>,
            },
            {
                title: 'Grocery Delivery',
                path: '/delieryapps/grocery-delivery',
                cName: 'nav-link',
                comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Grocery Delivery`}/>,
            },
            {
                title: 'Medical Eqipments Delivery',
                path: '/delieryapps/medical-eqipments-delivery',
                cName: 'nav-link',
                comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Medical Eqipments Delivery`}/>,
            },
            {
                title: 'Autoparts Delivery',
                path: '/delieryapps/autoparts-delivery',
                cName: 'nav-link',
                comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Autoparts Delivery`}/>,
            }

        ]
    },
    {
             title: 'On Demand Service',
             path: '/on-demand-services',
             cName: 'nav-link dropdown-toggle',
             comp: <DelieryPage title={`${process.env.REACT_APP_SITE_TITLE}: On Demand Service`}/>,
             submenu: [
                {
                    title: 'Car Wash on Demand',
                    path: '/on-demand-services/car-wash-on-demand',
                    cName: 'nav-link',
                    comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Car Wash On Demand`}/>,
                },
                {
                    title: 'Multi Service App',
                    path: '/on-demand-services/multi-service-app',
                    cName: 'nav-link',
                    comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}:  Multi Service App`}/>,
                },
                {
                    title: 'Urbal Clap Clone',
                    path: '/on-demand-services/urbal-clap-clone',
                    cName: 'nav-link',
                    comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Urbal Clap Clone`}/>,
                },
                {
                    title: 'Beautician On Demand',
                    path: '/on-demand-services/beautician-on-demand',
                    cName: 'nav-link',
                    comp: <DeliveryApp title={`${process.env.REACT_APP_SITE_TITLE}: Beautician On Demand`}/>,
                },
            ]

    },

    // {
    //     title: 'Gojeck Clone',
    //     path: '/gojeck-clone',
    //     cName: 'nav-link',
    //     comp: <TestPage title={`${process.env.REACT_APP_SITE_TITLE}: Gojeck Clone`}/>,
    // },
    // {
    //     title: 'Uber For X',
    //     path: '/uber-for-x',
    //     cName: 'nav-link',
    //     comp: <TestPage title={`${process.env.REACT_APP_SITE_TITLE}: Uber For X`}/>,
    // },
    // {
    //     title: 'Clients',
    //     path: '/clients',
    //     cName: 'nav-link',
    //     comp: <TestPage title={`${process.env.REACT_APP_SITE_TITLE}: Clients`}/>,
    // },
    {
        title: 'Our Clients',
        path: '/our-clients',
        cName: 'nav-link',
        comp: <TestPage title={`${process.env.REACT_APP_SITE_TITLE}: Our Clients`}/>,
    },
    {
        title: 'About Us',
        path: '/about-us',
        cName: 'nav-link',
        comp: <AboutUs title={`${process.env.REACT_APP_SITE_TITLE}: About Us`}/>,
    },
];