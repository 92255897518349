import React from 'react';
import Logo from './Logo';
import PrimaryNavebar from './PrimaryNevbar';
function Header() {
    return (
        <>
            <header id="header" className="header py-lg-5">
                <div className="container-xl">
                <nav className="navigation-wrapper navbar-light navbar navbar-expand-lg py-md-0 px-0">
                    <Logo />
                    <PrimaryNavebar />
                </nav>
                </div>
            </header>
        </>
    )
}
export default Header