import React from 'react';

function Features() {
    return (
        <>
              <section className="features-wrap py-3 py-md-5 bg-light">
                <div className="container-xl pb-3 pb-md-5">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-6">
                    <div className="title py-3 py-lg-5">
                        <h6 className="text-danger">A Collection of Prominent Features</h6>
                        <h2 className="title text-dark font-bold mb-4">
                        Delivering Exceptional

                        <br className="d-none d-lg-block" />

                        User Experiences
                        </h2>
                    </div>
                    </div>
                    <div className="col-md-6">
                    <h6>
                        Technology Platform for On-Demand Businesses. Including Apps for Users, Store Owners, Administrators,
                        Drivers for Real-time Tracking and more, to increase your income.
                    </h6>

                    </div>

                </div>


                <div className="row">
                    <div className="col-md-4 mb-4">
                    <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                        <div className="card-body">
                        <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                            <i className="las la-thumbs-up la-2x text-white"></i>
                        </span>
                        <h4 className="feature-title font-bold text-primary">Fast Performance
                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim ipsum lacus, feugiat venenatis ac. Nunc
                            facilisi duis molestie elit orci, vestibulum.</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 mb-4">
                    <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                        <div className="card-body">
                        <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                            <i className="las la-sliders-h la-2x text-white"></i>
                        </span>
                        <h4 className="feature-title font-bold text-primary">Easy Customize

                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim ipsum lacus, feugiat venenatis ac. Nunc
                            facilisi duis molestie elit orci, vestibulum.</p>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-4 mb-4">
                    <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                        <div className="card-body">
                        <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                            <i className="las la-gem la-2x text-white"></i>
                        </span>
                        <h4 className="feature-title font-bold text-primary"> Pure & Elegant

                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim ipsum lacus, feugiat venenatis ac. Nunc
                            facilisi duis molestie elit orci, vestibulum.</p>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-4 mb-4">
                    <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                        <div className="card-body">
                        <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                            <i className="las la-shield-alt la-2x text-white"></i>
                        </span>
                        <h4 className="feature-title font-bold text-primary">Secure Data

                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim ipsum lacus, feugiat venenatis ac. Nunc
                            facilisi duis molestie elit orci, vestibulum.</p>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-4 mb-4">
                    <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                        <div className="card-body">
                        <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                            <i className="las la-clock la-2x text-white"></i>
                        </span>
                        <h4 className="feature-title font-bold text-primary">Secure Data

                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim ipsum lacus, feugiat venenatis ac. Nunc
                            facilisi duis molestie elit orci, vestibulum.</p>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-4 mb-4">
                    <div className="card features-card bg-white shadow h-100 text-center wow fadeInUp">
                        <div className="card-body">
                        <span className="circle-icon d-flex align-items-center justify-content-center mx-auto my-4 bg-danger">
                            <i className="las la-headphones la-2x text-white"></i>
                        </span>
                        <h4 className="feature-title font-bold text-primary">Secure Data

                        </h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim ipsum lacus, feugiat venenatis ac. Nunc
                            facilisi duis molestie elit orci, vestibulum.</p>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="row justify-content-between align-items-center py-5">
                    <div className="col-md-6">
                    <h5>Tech, Coding stuff & Hassle on us, Growth on you.
                        <br />
                        How cool is that ?
                    </h5>
                    </div>
                    <div className="col-md-auto">
                    <a href="#" className="btn btn-success mr-2">Request An Estimation</a>
                    <a href="#" className="btn btn-outline-primary">Schedule a Call</a>

                    </div>

                </div>
                </div>
            </section>
        </>
    )
}
export default Features