import React from 'react'
import image1 from '../../images/assets/business-solution.png';
function BusinessSolution() {
    return (
        <>
            <section className="business-wrap py-3 py-md-5">
                <div className="container-xl pb-3 pb-md-5">
                <div className="row pt-5">
                    <div className="col-lg-6">
                        <div className="image-module mb-4 pb-5 pb-md-0 wow fadeInUp">
                            <img src={image1} alt="business solution" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="about-info">
                        <div className="title wow fadeInUp">
                        <h6 className="text-danger">Because we deliver the best & we are unbeatable.</h6>
                        <h2 className="title text-dark font-bold mb-4">
                            COVID-19 Proof On-demand Business Solutions To Adapt To The New-Normal
                        </h2>
                        </div>
                        <div className="pb-3">
                        <p>
                            Purchase a Ready Made On Demand Application from us and start making money right from day 1! Team
                            MarketPlace supports you in fulfilling your dream to be a successful Entrepreneur in just 5-7 business
                            days!
                        </p>
                        </div>
                        <a href="#" className="btn-fancy btn-fancy-primay"> Read More <i className="la la-long-arrow-right la-lg"></i></a>
                    </div>

                    </div>
                </div>

                </div>
            </section>
        </>
    )
}
export default BusinessSolution