import React from 'react';
import AboutUs from '../Pages/AboutUs';
import Careers from '../Pages/Careers';
import Blog from '../Pages/Blog';
import ContactUs from '../Pages/ContactUs';
export const CompanyMenu = [
    {
        title: 'About Us',
        path: '/about-us',
        cName: '',
        comp: <AboutUs title={`${process.env.REACT_APP_SITE_TITLE}: About Us`}/>,
    },
    {
        title: 'Careers',
        path: '/careers',
        cName: '',
        comp: <Careers title={`${process.env.REACT_APP_SITE_TITLE}: Careers`}/>,
    },
    {
        title: 'Blogs',
        path: '/blog',
        cName: '',
        comp: <Blog title={`${process.env.REACT_APP_SITE_TITLE}: Blogs`}/>,
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        cName: '',
        comp: <ContactUs title={`${process.env.REACT_APP_SITE_TITLE}: Contact Us`}/>,
    },
];