import React, {useEffect} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import { scrollTop } from "../utils/comman";
function Careers(props) {
  useEffect(() => {
    scrollTop();
  }, [])
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <div className="page-header py-lg-5 bg-light mb-5">
        <div className="container-xl">
          <h2 className="page-header-text text-4xl font-bold text-dark pt-lg-5 mt-lg-5">
            Careers
          </h2>
        </div>
      </div>
      <section className="inner-content-wrap">
        <div className="container-xl pb-3 pb-md-5"></div>
      </section>
      <Footer />
    </>
  );
}

export default Careers;
