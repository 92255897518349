import React from "react";

import groceryImg from "../images/assets/grocery-delivery.png";
import alcoholImg from "../images/assets/alcohol-delivery.png";
import flowerImg from "../images/assets/flower-delivery.png";
import waterImg from "../images/assets/water-delivery.png";
import iceCreamImg from "../images/assets/ice-cream-delivery.png";
import stationaryImg from "../images/assets/stationary-delivery.png";
import { Link } from "react-router-dom";
function OtherApps(props) {
  const appData = [
    {
      title: "Grocery Delivery",
      path: "/abc",
      image: window.location.origin +groceryImg,
    },
    {
      title: "Alcohol Delivery",
      path: "/",
      image: window.location.origin +alcoholImg,
    },
    {
      title: "Flower Delivery",
      path: "/",
      image: window.location.origin +flowerImg,
    },
    {
      title: "Water Delivery",
      path: "/",
      image: window.location.origin +waterImg,
    },
    {
      title: "Ice Cream Delivery",
      path: "/",
      image: window.location.origin +iceCreamImg,
    },
    {
      title: "Stationary Delivery",
      path: "/",
      image: window.location.origin +stationaryImg,
    },
  ];
  return (
    <>
      <section className="category-wrap py-3 py-md-5 bg-light">
        <div className="container-xl">
          <div className="title text-center py-3 py-lg-5">
            <h6 className="text-danger">Other Types of Delivery </h6>
            <h2 className="title text-dark font-bold mb-4">
              See what else you can make deliveries of and earn revenues
            </h2>
          </div>

          <div className="row row-cols-sm-6 row-cols-md-3 row-cols-xl-3 text-center">
              
            {appData.map((item, index) => {
                if(item.title != props.currentApp){
                    return (
                        <>
                          <div className="col mb-4">
                            <div className="card border-0 border-radius-sm category-card bg-white shadow h-100 wow fadeInUp">
                              <div className="card-body">
                                <div className="mb-3 category-card-icon">
                                  <img
                                    src={item.image}
                                    alt={item.title}
                                  />
                                </div>
                                <h4 className="category-card-title font-bold text-primary">
                                  {item.title}
                                </h4>
                                <Link
                                  key={index}
                                  to={item.path}
                                  className="text-success"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                }
              
            })}
          </div>
          <hr />
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <h5>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Reprehenderit, magnam.
              </h5>
            </div>
            <div className="col-md-auto">
              <a href="#" className="btn btn-success mr-2">
                Buy Now
              </a>
              <a href="#" className="btn btn-outline-primary">
                Demo
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OtherApps;
