import React from "react";
import { SocialMenu } from "./SocialMenu";

function SocialNavbar(props) {
  return (
    <>
      <div class="col-md-6">
        <div class="footer-link-widgets">
          <h6 class="text-white text-uppercase">Connect with us</h6>
          <div class="social-networks pt-3">
            {SocialMenu.map((item, index) => {
              return (
                <a className={item.cName} href={item.path} target="_blank">
                  <i className={item.title}></i>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialNavbar;
