import React from "react";
import { HelpCenterMenu } from "./HelpCenterMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
function HelpCenterNavebar() {
  return (
    <>
      <div className="col-md-6">
        <div className="footer-link-widgets">
          <h6 className="text-white text-uppercase">Help center</h6>
          <ul className="list-unstyled">
            {HelpCenterMenu.map((item, index) => {
              if (!("comp" in item)) {
                return (
                  <li key={index}>
                    <a href={item.externalurl} className={item.cName}>
                      {item.title}
                    </a>
                  </li>
                );
              }else{
                return (
                    <li key={index}>
                    
                    <NavLink 
                    key={index} to={item.path} className={item.cName}>
                        {item.title}
                    </NavLink>
                    
                    </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
export default HelpCenterNavebar;
