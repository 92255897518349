import React, {useEffect} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import { scrollTop } from "../utils/comman";
function ContactUs(props) {
  useEffect(() => {
    scrollTop();
  }, [])
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <div className="page-header py-lg-5 bg-light mb-5">
        <div className="container-xl">
          <h2 className="page-header-text text-4xl font-bold text-dark pt-lg-5 mt-lg-5">
            Contact Us
          </h2>
        </div>
      </div>
      <section className="inner-content-wrap">
        <div className="container-xl pb-3 pb-md-5">
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <form method="POST" action="/">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    aria-describedby="emailHelp"
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    class="form-control"
                    id="message"
                    name="message"
                    rows="3"
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
