import React from 'react';

export const SocialMenu = [
    {
        title: 'la la-facebook-f',
        path: 'https://facebook.com/',
        cName: 'facebook',
    },
    {
        title: 'la la-twitter',
        path: 'https://twitter.com/',
        cName: 'twitter',
    },
    {
        title: 'la la-linkedin',
        path: 'https://www.linkedin.com/',
        cName: 'linkedin',
    },
 
];