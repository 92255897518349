import React from "react";

function Proccess() {
  return (
    <>
      <section className="proccess-warp py-5">
        <div className="container-xl">
          <div className="title text-center pb-5 wow fadeInUp">
            <h6 className="text-secondary">Our Proccess </h6>
            <h2 className="title text-dark font-semibold mb-4">How do we work?</h2>
          </div>
          <div className="proccess-module">
            <div className="row row-cols-md-6 justify-content-center">
              <div className="col proccess-col">
                <div className="proccess-card text-center d-flex flex-column justify-content-center align-items-center">
                  <div className="proccess-number text-6xl font-bold">1</div>
                  <h6 className="proccess-title">Select the product</h6>
                </div>
              </div>
              <div className="col proccess-col">
                <div className="proccess-card text-center d-flex flex-column justify-content-center align-items-center">
                  <div className="proccess-number text-6xl font-bold">2</div>
                  <h6 className="proccess-title">Have Customization?</h6>
                </div>
              </div>
              <div className="col proccess-col">
                <div className="proccess-card text-center d-flex flex-column justify-content-center align-items-center">
                  <div className="proccess-number text-6xl font-bold">3</div>
                  <h6 className="proccess-title">Place Order</h6>
                </div>
              </div>
              <div className="col proccess-col">
                <div className="proccess-card text-center d-flex flex-column justify-content-center align-items-center">
                  <div className="proccess-number text-6xl font-bold">4</div>
                  <h6 className="proccess-title">White Labelling in 3 days</h6>
                </div>
              </div>
              <div className="col proccess-col">
                <div className="proccess-card text-center d-flex flex-column justify-content-center align-items-center">
                  <div className="proccess-number text-6xl font-bold">5</div>
                  <h6 className="proccess-title">Launch</h6>
                </div>
              </div>
              <div className="col proccess-col">
                <div className="proccess-card text-center d-flex flex-column justify-content-center align-items-center">
                  <div className="proccess-number text-6xl font-bold">6</div>
                  <h6 className="proccess-title">Support</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Proccess;
