import React from 'react';
import CookiePolicy from '../Pages/CookiePolicy';
import PrivacyPolicy from '../Pages/PrivacyPolicy';
import TermsConditions from '../Pages/TermsConditions';

export const HelpCenterMenu = [
    {
        title: 'Cookie Policy',
        path: '/cookie-policy',
        cName: '',
        comp: <CookiePolicy title={`${process.env.REACT_APP_SITE_TITLE}: Cookie Policy`}/>,
    },
    {
        title: 'Privacy Policy',
        path: '/privacy-policy',
        cName: '',
        comp: <PrivacyPolicy title={`${process.env.REACT_APP_SITE_TITLE}: Privacy Policy`}/>,
    },
    {
        title: 'Terms & Conditions',
        path: '/terms-conditions',
        cName: '',
        comp: <TermsConditions title={`${process.env.REACT_APP_SITE_TITLE}: Terms & Conditions`}/>,
    },
];