import React, {useEffect} from 'react'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import {Helmet} from "react-helmet";
import { scrollTop } from "../utils/comman";
function DelieryPage(props) {
    useEffect(() => {
        scrollTop();
      }, [])
    return (
        <>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <Header />   
            <h1>DelieryPage</h1>
            <Footer />
        </>
    )
}
export default DelieryPage