import React, {useEffect} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import { scrollTop } from "../utils/comman";
function AboutUs(props) {
  useEffect(() => {
    scrollTop();
  }, [])
  return (
    <>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <Header />
      <div className="page-header py-lg-5 bg-light mb-5">
        <div className="container-xl">
          <h2 className="page-header-text text-4xl font-bold text-dark pt-lg-5 mt-lg-5">
            About Us
          </h2>
        </div>
      </div>
      <section className="inner-content-wrap">
        <div className="container-xl pb-3 pb-md-5">
          <h6 className="text-danger">Lorem ipsum dolor sit amet.</h6>
          <h2 className="title text-dark font-bold mb-4">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit.
          </h2>

          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugiat
            pariatur ad illo id, itaque fuga doloremque ex nisi, illum nemo
            dolorem voluptatibus totam natus tenetur ullam mollitia cumque
            aliquid in laudantium harum error libero placeat?
          </p>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam
            explicabo dignissimos recusandae adipisci! Ut modi doloremque et at
            ipsam numquam error quis provident facere consequuntur excepturi
            vitae reprehenderit facilis laborum eius nihil nulla, ducimus
            assumenda praesentium natus, distinctio unde animi. Dolorum fugit
            delectus voluptates autem ullam. Distinctio beatae reprehenderit
            sint?
          </p>

          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
            eaque, id ullam voluptas beatae illum natus, sunt iure blanditiis
            libero ad tempora mollitia odio perferendis quasi hic fugiat
            repellendus pariatur, atque rem eius illo assumenda recusandae
            similique. Quia distinctio autem labore magnam corporis in placeat
            sint consectetur impedit quidem harum, optio molestiae dolorem iste
            molestias velit asperiores blanditiis quasi reprehenderit sit
            dolores architecto ipsa modi? Architecto pariatur consequuntur
            corporis fuga nisi cupiditate consectetur error dolorum.
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AboutUs;
