import React from 'react'
import image1 from '../../images/assets/business-solution.png';
function MobileApplication() {
    return (
        <>
        <section className="mobile-app-wrap py-3 py-md-5">
            <div className="container-xl pb-3 pb-md-5">
            <div className="row pt-5">
                <div className="col-lg-6 order-1 order-md-2">
                <div className="image-module mb-4 pb-5 pb-md-0 wow fadeInUp">
                    <img src={image1} alt="business solution" className="img-fluid" />
                </div>
                </div>
                <div className="col-lg-6 order-2 order-md-1">
                <div className="about-info">
                    <div className="title wow fadeInUp">
                    <h6 className="text-danger">More than 1200 </h6>
                    <h2 className="title text-dark font-bold mb-4">
                        Mobile Applications Launched Since Jan 2016 Till Date.
                    </h2>
                    <h6 className="text-semibold">we connect the dots between marketing technology & creativity to help you engage
                        customers & grow
                        revenue.</h6>
                    </div>
                    <ul className="list-style-check">
                    <li>Instant Entry into the market.</li>
                    <li>Ownership of Apps with License to use for your Domain.</li>
                    <li>Start Earning from DAY 1 once you go live.</li>
                    <li>Try the Apps in Demo Mode and Buy only if you are satisfied. </li>
                    </ul>
                    <a href="#" className="btn btn-danger mr-2">Request An Estimation</a>
                    <a href="#" className="btn btn-outline-primary">Schedule a Call</a>
                </div>

                </div>
            </div>


            </div>
        </section>
        </>
    )
}
export default MobileApplication
