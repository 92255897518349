import "./App.css";
import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import EnquiryNow from './Pages/EnquiryNow';
import { PrimaryMenu } from "./Components/PrimaryMenu";
import { HelpCenterMenu } from "./Components/HelpCenterMenu";
import { CompanyMenu } from "./Components/CompanyMenu";

import $ from "jquery";
function App() {

  return (
    <div className="App">

      <Routes>
       {PrimaryMenu.map((item, index) => {

          if (!("submenu" in item)) {
            return (
              <Route path={item.path} exact element={item.comp} key={index} />
            );
          } else {
            return (
              <>
                <Route path={item.path} key={index}>
                  <Route index={true} element={item.comp} />
                  {item.submenu.map((subitem, subindex) => {
                    return (
                      <Route
                        path={subitem.path}
                        element={subitem.comp}
                        key={subindex}
                      />
                    );
                  })}
                </Route>
              </>
            );
          }
        })}
        {HelpCenterMenu.map((item, index) => {
          if (!("submenu" in item)) {
            return (
              <Route path={item.path} exact element={item.comp} key={index} />
            );
          } else {
            <Outlet />;
            return (
              <Route path={item.path} element={item.comp} key={index}>
                {item.submenu.map((subitem, subindex) => {
                  return (
                    <Route
                      path={subitem.path}
                      element={subitem.comp}
                      key={subitem.path}
                    />
                  );
                })}
              </Route>
            );
          }
        })}
        {CompanyMenu.map((item, index) => {
          if (!("submenu" in item)) {
            return (
              <Route path={item.path} exact element={item.comp} key={index} />
            );
          } else {
            <Outlet />;
            return (
              <Route path={item.path} element={item.comp} key={index}>
                {item.submenu.map((subitem, subindex) => {
                  return (
                    <Route
                      path={subitem.path}
                      element={subitem.comp}
                      key={subitem.path}
                    />
                  );
                })}
              </Route>
            );
          }
        })}

      <Route path="/enquiry-now" exact element={<EnquiryNow/>} key="aaaaa" />

      </Routes>
    </div>
  );
}

export default App;
